<template>
  <div class="container-fluid">
    <breadcrumb>
    <img  src="static/img/appetite.svg" class="breadcrumb-image" />
      <breadcrumb-item active> Qualitative Settings </breadcrumb-item>
    </breadcrumb>
    <div class="row">
      <div class="col-md-12">
        <card v-if="loaded">
          <div>
            <ul class="data-tile row" style="padding-top: 20px">
              <li
                v-for="appetite in appetites"
                :key="appetite.id"
                :value="appetite.id"
                class="col-lg-4"
              >
                <div
                  class="data-tile-card cursor-pointer text-center"
                  @click="$router.push(`/appetites/${appetite.id}/edit`)"
                >
                  <div class="card-body">
                    <div
                      v-if="appetite.name === 'Control Assessment'"
                      class="data-tile-icon fa fa-shield-alt control-icon"
                    ></div>
                    <div
                      v-if="appetite.name === 'Vulnerability Assessment'"
                      class="data-tile-icon fa fa-lock-open vulnerability-icon"
                    ></div>
                    <div
                      v-if="appetite.name === 'Risk Assessment'"
                      class="data-tile-icon fa fa-dice risk-icon"
                    ></div>
                    <br />
                    <br />
                    <span class="data-tile-card-text">{{ appetite.name }}</span>
                    <br />
                    <span class="data-tile-card-text">Settings</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { Breadcrumb, BreadcrumbItem } from "src/components/index";

export default {
  components: {
    Breadcrumb,
    BreadcrumbItem,
  },
  data() {
    return {
      appetites: [],
      loaded: false,
    };
  },

  created() {
    let self = this;
    self.getAll();
  },

  methods: {
    getAll() {
      let self = this;
      self.$store.state.services.appetiteService
        .getAll()
        .then((r) => {
          self.appetites = r.data;
          self.loaded = true;
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>
<style>
</style>


